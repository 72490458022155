import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image";

import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const SchoolsPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const title = data.markdownRemark.frontmatter.title;
  const bannerTitle = data.markdownRemark.frontmatter.banner.small_title;
  const bannerCaption = data.markdownRemark.frontmatter.banner.caption;
  const schoolsContent = data.markdownRemark.frontmatter.schools;

  // Loop over school sections for display
  const Schools = () => {
    // Create each school, convert markdown to html
    const School = ({ title, caption, image, url }) => {
      return (
        <div className="col col-one-half">
          <Link to={url} className="school">
            <div className="media">
              <div className="logo-wrapper">
                <GatsbyImage image={image} />
              </div>
              <div className="media-body">
                <h2>{title}</h2>
                <p>{caption}</p>
              </div>
            </div>
          </Link>
        </div>
      );
    }
    return (
      <section className="schools">
        <div className="container">
          <Fade triggerOnce>
            <div className="row">
              {schoolsContent.map((school, index) =>
                <School title={school.title} caption={school.caption} image={school.image.childImageSharp.gatsbyImageData} url={school.url} key={index} />
              )}
            </div>
          </Fade>
        </div>
      </section>
    );
  }

  return (
    <Layout navSolid="true">
      <Seo title={title} description={bannerCaption} />
      <section className="intro">
        <div className="container">
          <Fade triggerOnce>
            <h1>{bannerTitle}</h1>
            <Breadcrumb crumbs={crumbs} crumbSeparator=" / " />
            <p className="lead">{bannerCaption}</p>
          </Fade>
        </div>
      </section>
      <Schools />
    </Layout>
  )
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "schools"}}) {
    frontmatter {
      title
      banner {
        small_title
        caption
        banner_image {
          childImageSharp {
            gatsbyImageData(width: 900, quality: 100, layout: CONSTRAINED)
          }
        }
      }
      schools {
        title
        caption
        image {
          childImageSharp {
            gatsbyImageData(
              width: 900
              quality: 100
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
        url
      }
    }
  }
}
`

export default SchoolsPage
